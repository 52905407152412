// Types
import type { NavigationLink } from 'layouts/ProfileNavigation/components/NavigationSection/components/NavigationItem/NavigationItem';
// Hooks
import { useGetCurrUser } from 'hooks';
// Constants
import {
  PURCHASES,
  ACCOUNT,
  ADDRESSES,
  PAYMENT,
  DASHBOARD,
  PROFILE,
  MY_SALES,
  MANAGE_MERCH,
  MANAGE_STREAMS,
  ACCOUNT_PRODUCTS,
  MANAGE_EXPERIENCES,
  MEDIA_LIBRARY,
  CHANNEL,
  MANAGE_AMA,
} from 'constants/routes';
// Helpers
import {
  isUserAthlete,
  isOnboardedStore,
  isUserOrganization,
  isUserContentCreator,
} from 'helpers/user';
// Components
import NavigationSection from 'layouts/ProfileNavigation/components/NavigationSection/NavigationSection';

import styles from './ProfileNavigation.module.scss';

const USER_LINKS: NavigationLink[] = [
  {
    icon: 'shopping-cart-bordered',
    label: 'Orders',
    href: PURCHASES,
  },
  {
    icon: 'v2-person',
    label: 'Account',
    href: ACCOUNT,
  },
  {
    icon: 'organisation',
    label: 'Addresses',
    href: ADDRESSES,
  },
  {
    icon: 'v2-credit-card',
    label: 'Payment',
    href: PAYMENT,
  },
];

const ProfileNavigation = () => {
  const { data } = useGetCurrUser();

  const isAthlete = isUserAthlete(data);
  const isOrganization = isUserOrganization(data);
  const isContentCreator = isUserContentCreator(data);
  const isOnboarded = isOnboardedStore(data);
  const showStoreLinks = isAthlete || isOrganization || isContentCreator;

  const onboardedLinks: NavigationLink[] = isOnboarded
    ? [
        {
          icon: 'shopping-bag',
          label: 'Merch',
          href: MANAGE_MERCH,
        },
        {
          icon: 'camera-v2',
          label: 'streams',
          href: MANAGE_STREAMS,
        },
        {
          icon: 'webcam',
          label: 'Brand Deals',
          href: MANAGE_AMA,
        },
        {
          icon: 'record-icon',
          label: 'channel',
          href: CHANNEL,
        },
      ]
    : [];

  const storeLinks: NavigationLink[] = [
    {
      icon: 'v2-dashboard',
      label: 'Dashboard',
      href: DASHBOARD,
    },
    {
      icon: 'profile',
      label: 'Profile',
      href: PROFILE,
    },
    {
      icon: 'v2-loyalty',
      label: 'Sales',
      href: MY_SALES,
    },
    ...onboardedLinks,
    {
      icon: 'suitcase',
      label: 'Products',
      href: ACCOUNT_PRODUCTS,
    },
    {
      icon: 'user',
      label: 'Experiences',
      href: MANAGE_EXPERIENCES,
    },
    {
      icon: 'upload-file',
      label: 'Media Feed',
      href: MEDIA_LIBRARY,
    },
  ];

  return (
    <aside className={styles.root} data-testid="sideBar">
      <div className={styles.content}>
        {showStoreLinks && (
          <NavigationSection links={storeLinks} title="My business" />
        )}

        <NavigationSection links={USER_LINKS} title="My page" />
      </div>
    </aside>
  );
};

export default ProfileNavigation;
